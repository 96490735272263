<template>
  <Form :data="formData">

    <FormItem label="选择返现团长">
      <Badge :count="userIds ? userIds.length : 0">
        <Button @click="showSellerSelector = true" :disabled="disabled">打开用户列表</Button>
      </Badge>
      <Tag v-for="(item, idx) in selectedUsers" :key="idx" :name="idx">
        {{ item.name }}
      </Tag>
    </FormItem>
    <FormItem label="返现额度">
      <InputNumber
        :min="0.01"
        :max="10000000"
        v-model="formData.cashBackAmount"
        placeholder="使用优惠码后，具体返现金额数字"
        style="width: 150px"
        :precision="2"
      />
      $
    </FormItem>

    <Card :bordered="false" dis-hover>
      <p slot="title">
        团长自提地址
      </p>
      <Row type="flex" justify="start">
        <Col span="16">
          <FormItem label="地址">
            <Input v-model="formData.location.fullAddress" placeholder="" />
          </FormItem>
        </Col>
      </Row>

      <Row type="flex" justify="start">
        <Col span="7">
          <FormItem label="收件人:">
            <Input v-model="formData.location.contact" placeholder="收件人" />
          </FormItem>
        </Col>
        <Col span="1"></Col>
        <Col span="7">
          <FormItem label="收件人联系电话:">
            <Input v-model="formData.location.phone" placeholder="收件人电话" />
          </FormItem>
        </Col>
      </Row>

    </Card>


    <Modal v-model="showSellerSelector" title="选择团长" fullscreen>
      <UserSelector :selected="userIds" @selectionChanged="userSelectionChanged" v-if="showSellerSelector" />
      <div slot="footer">
        <Button @click="showSellerSelector = false">确定</Button>
      </div>
    </Modal>

  </Form>

</template>

<script>
import { queryUserByIds } from "@/api"
import UserSelector from "@/components/promocode/SellerSelector"

const initData = () => {
  return {
    targetUserId: -1,
    cashBackAmount: 0,
    expired: 0,
    expiredDate: null,
    quantity: 0,
    sellerName: "",
    location: {
      fullAddress: "",
      country: "NewZealand",
      city: "",
      streetNo: "",
      region: "",
      district: "",
      street: "",
      suburb: "",
      lat: 0,
      lnt: 0,
      postCode: "",
      contact: "",
      phone: "",
    },
  }
}

export default {
  name: "CashBackerEditor",
  components: { UserSelector },
  props: {
    form: {
      type: Object,
      default: () => initData(),
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expiredDateOpts: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000
        },
      },
      userIds: [],
      formData: initData(),
      showSellerSelector: false,
      selectedSeller: null,
      selectedUsers: [],
    }
  },
  computed: {},
  watch: {
    formData: {
      deep: true,
      handler(newV) {
        this.$emit("update:form", newV)
      },
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async userSelectionChanged(selection) {
      console.log(selection)
      this.userIds = [selection]
      this.selectedUsers = await queryUserByIds([selection])
      this.formData.targetUserId = selection
      this.formData.sellerName = this.selectedUsers[0].name

    },
    async reload() {
    },
  },
}
</script>

<style></style>
