<template>
  <div>
    <Form inline>
      <FormItem>
        <Input v-model="queryForm.name" type="text" placeholder="名称" />
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.nickname" type="text" placeholder="昵称" />
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="创建时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table
      stripe
      :data="items"
      :columns="tableColumns"
      style="width: 100%"
      :loading="tableLoading"
      @on-select="onSelect"
      @on-select-cancel="onSelectCancel"
      @on-select-all="onSelectAll"
      @on-select-all-cancel="onSelectAllCancel"
      ref="selection"
    />
    <Pager :total="itemListCount" :current.sync="page" />
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue"
import { queryUser, queryUserByIds } from "@/api"
import "@/components/tableUtil"

export default {
  name: "SellerSelector",
  components: { Pager },
  props: {
    selected: { type: Array, default: () => [] },
    max: { type: Number, default: 100 },
    currentAlias: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      newSelected: [],
      items: [],
      itemListCount: 0,
      page: 1,
      createdAtRange: ["", ""],
      queryForm: { status: "" },
      selectedUsers: [],
      tableLoading: false,
      m_currentAlias:this.currentAlias,
      tableColumns: [
        {
          title: "选择",
          width: 40,
          maxWidth: 40,
          fixed:"left",
          render: (h, params) => {
            const alias = params.row.alias
            let defaultS = false
            if (this.m_currentAlias == alias) {
              defaultS = true
            } else {
              defaultS = false
            }
            const self = this
            return h("Radio",
              {
                props: { value: defaultS },
                on: {
                  "on-change": () => {
                    self.setAlias(alias)
                    this.$emit("selectionChanged", params.row.id)
                  },
                },
              })
          },
        },
        { title: "用户唯一标识", key: "alias", fixed: "left", width: 300, maxWidth: 300 },
        { title: "通行证 ID", key: "passportUid", fixed: "left", width: 90, maxWidth: 90 },
        { title: "名称", key: "name", width: 100, maxWidth: 100 },
        { title: "昵称", key: "nickname", width: 200, maxWidth: 200 },
        {
          title: "创建时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.createdAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
      ],
    }
  },
  computed: {},
  watch: {
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
    async page() {
      await this.reload()
    },
    async createdAtRange() {
      await this.reload()
    },
    async newSelected(newV) {
      if (newV && newV.length > 0) {
        this.selectedUsers = await queryUserByIds(newV)
      } else {
        this.selectedUsers = []
      }
      this.$emit("usersChanged", this.selectedUsers)
    },
  },
  async mounted() {
    if (this.selected) {
      this.newSelected = this.newSelected.concat(this.selected)
    } else {
      this.newSelected = []
    }
    await this.reload()
  },
  methods: {
    onSelect(selection, row) {

      this.newSelected = []
      if (!this.newSelected.includes(row.id)) {
        this.newSelected.push(row.id)
      }
      this.$emit("selectionChanged", this.newSelected, this.selectedUsers)
    },
    onSelectCancel(selection, row) {
      this.newSelected = this.newSelected.filter(i => i != row.id)
      this.$emit("selectionChanged", this.newSelected, this.selectedUsers)
    },
    onSelectAll(selection) {
      selection.forEach(s => {
        this.onSelect(selection, s)
      })
    },
    onSelectAllCancel(selection) {
      this.items.forEach(item => {
        this.onSelectCancel(selection, item)
      })
    },
    removeUser(id) {
      this.items = this.items.map(item => {
        if (item.id == id) {
          item._checked = false
        }
        return item
      })
      this.onSelectCancel(null, { id })
    },
    setAlias(alias) {
      this.m_currentAlias = alias
    },
    getAlias() {
      return this.m_currentAlias
    },
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        const name = q.name === "" ? null : q.name
        const nickname = q.name === "" ? null : q.nickname
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        const { items, count } = await queryUser({
          page: this.page,
          name,
          nickname,
          startTime,
          endTime
        })
        items.forEach(item => {
          if (this.newSelected.includes(item.id)) {
            item._checked = true
          }
        })
        this.items = items
        this.itemListCount = count
      } catch (e) {
        this.$Message.error(e.message)
      } finally {
        this.tableLoading = false
      }
    },
  },
}
</script>
