<template>
  <div>
    <Tag v-for="(user, idx) in selectedUsers" :key="idx" :name="idx" closable @on-close="removeUser(user.id)">
      {{ user.name }}
    </Tag>
    <Form inline>
      <FormItem>
        <Input v-model="queryForm.name" type="text" placeholder="名称" />
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.nickname" type="text" placeholder="昵称" />
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.shopName" type="text" placeholder="店铺名称" />
      </FormItem>
      <FormItem>
        <DatePicker v-model="createdAtRange" type="datetimerange" placement="bottom-end" placeholder="创建时间范围"
          style="width: 200px"></DatePicker>
      </FormItem>
    </Form>
    <Table stripe :data="items" :columns="tableColumns" style="width: 100%" :loading="tableLoading" />
    <Pager :total="itemListCount" :current.sync="page" />
  </div>
</template>
  
<script>
import Pager from "@/components/Pager.vue"
import { queryUser, queryUserWithoutStore } from "@/api"
import "@/components/tableUtil"

export default {
  name: "UserSelector",
  components: { Pager },
  props: {
    max: { type: Number, default: 100 },
    withoutStore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [],
      itemListCount: 0,
      page: 1,
      createdAtRange: ["", ""],
      queryForm: { status: "" },
      selectedUsers: [],
      tableLoading: false,
      tableColumns: [
        {
          title: "用户唯一标识",
          key: "alias",
          fixed: "left",
          width: 300,
          maxWidth: 300,
          render: (h, params) => {
            return h(
              "a",
              {
                on: {
                  click: () => {
                    this.$emit("selectionChanged", params.row)
                  },
                },
              },
              [params.row.alias]
            )
          }
        },
        { title: "通行证 ID", key: "passportUid", fixed: "left", width: 90, maxWidth: 90 },
        { title: "名称", key: "name", width: 100, maxWidth: 100 },
        { title: "昵称", key: "nickname", width: 200, maxWidth: 200 },
        { title: "店铺名", key: "shopName", width: 150, maxWidth: 300 },
        {
          title: "创建时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.createdAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
      ],
    }
  },
  computed: {},
  watch: {
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
    async page() {
      await this.reload()
    },
    async createdAtRange() {
      await this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        const name = q.name === "" ? null : q.name
        const nickname = q.name === "" ? null : q.nickname
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        const shopName = q.shopName === "" ? null : q.shopName
        if (this.withoutStore) {
          const { items, count } = await queryUserWithoutStore(this.page, name, nickname, shopName, startTime, endTime)
          this.items = items
          this.itemListCount = count
        } else {
          const { items, count } = await queryUser({
            page: this.page,
            name,
            nickname,
            shopName,
            startTime,
            endTime
          })
          this.items = items
          this.itemListCount = count
        }
      } catch (e) {
        this.$Message.error(e.message)
      } finally {
        this.tableLoading = false
      }
    },
  },
}
</script>
