<template>
  <ContentWrapper :links="topLinks">
    <Form :labelWidth="100" style="padding-right: 100px" :disabled="false">
      <FormItem label="码名">
        <Input v-model="form.name" placeholder="请输入码名" />
      </FormItem>
      <FormItem label="优惠码">
        <Input v-model="form.code" placeholder="点击生成" />
        <Button @click="generatePormoCode" :disabled="false">生成优惠码</Button>
      </FormItem>
      <FormItem label="优惠码描述">
        <Input type="textarea" :rows="5" v-model="form.desc" placeholder="请输入描述" />
      </FormItem>

      <FormItem label="优惠对象">
        <RadioGroup v-model="form.type">
          <Radio :label="1">商品</Radio>
        </RadioGroup>

        <Badge v-if="form.type === 1" :count="form.targetIds ? form.targetIds.length : 0">
          <Button @click="showItemSelector = true" :disabled="false">打开商品列表</Button>
        </Badge>
        <Badge v-if="form.type === 2" :count="form.targetIds ? form.targetIds.length : 0">
          <Button @click="showItemSelector = true" :disabled="false">打开商店列表</Button>
        </Badge>
      </FormItem>
      <FormItem>
        <Tag v-for="(item, idx) in selectedItems" :key="idx" :name="idx" closable @on-close="removeItem(item.id)">
          {{ item.title }}
        </Tag>
      </FormItem>
      <FormItem label="码量">
        <RadioGroup v-model="form.quantity">
          <Radio :label="1">有限</Radio>
          <Radio :label="0">无限</Radio>
        </RadioGroup>
        <InputNumber :max="100000" :min="1" v-model="form.quantityNumber" v-if="form.quantity===1"
                     style="min-width: 150px" placeholder="请输入数量" />
      </FormItem>

      <FormItem label="优惠类型">
        <RadioGroup v-model="form.promotionType">
          <Radio label="discount">折扣</Radio>
          <Radio label="deduct">直减</Radio>
        </RadioGroup>
        <Card v-if="form.promotionType ==='deduct'">
          满足金额 $
          <InputNumber v-model="form.deductCond"
                       placeholder="请输入直减满足金额"
                       :precision="2"
                       :max="10000000"
                       :min="0"
                       style="min-width: 150px" />
          直减 $
          <InputNumber
            v-model="form.deductValue"
            placeholder="请输入直减金额"
            :precision="2"
            :max="10000000"
            :min="0"
            style="min-width: 150px"
          />
        </Card>

        <card v-if="form.promotionType==='discount'">
          <InputNumber
            v-model="form.discountValue"
            placeholder="请输入价格"
            :precision="2"
            :max="100"
            :min="0"
            style="min-width: 150px"
          />
          %
        </card>

      </FormItem>


      <FormItem label="是否返现">
        <i-switch v-model="form.isCashBack" />
      </FormItem>
      <FormItem label="选择返现团长" v-if="form.isCashBack">
        <Button type="primary" @click="showAddCashBacker" v-if="form.cashBackers.length<1">
          添加
        </Button>
        <Table stripe :data="form.cashBackers" :columns="cashBackTable" size="small" />
      </FormItem>

      <FormItem label="有效期限">
        <RadioGroup v-model="form.expired">
          <Radio :label="1">有限</Radio>
          <Radio :label="0">无限</Radio>
        </RadioGroup>
        <DatePicker v-if="form.expired===1" type="date" :options="expiredDateOpts" v-model="form.expiredDate" />
      </FormItem>

      <FormItem label="每个用户使用次数">
        <RadioGroup v-model="form.quantityPerBuyer">
          <Radio :label="1">有限</Radio>
          <Radio :label="0">无限</Radio>
        </RadioGroup>
        <InputNumber :max="100000" :min="1" v-model="form.quantityPerBuyerNumber" v-if="form.quantityPerBuyer===1"
                     style="min-width: 150px" placeholder="请输入数量" />
      </FormItem>

      <FormItem>
        <Button size="large" type="primary" @click="publish">发布</Button>
      </FormItem>
    </Form>
    <Modal v-model="showCashBackDialog" :closable="true" title="添加返现团长" width="70%">
      <CashBackerEditor :form.sync="cashBacker" :disabled="false" />
      <div slot="footer">
        <Button @click="addCashBacker" type="info">提交</Button>
      </div>
    </Modal>

    <Modal v-model="showItemSelector" fullscreen>
      <ItemSelector v-if="showItemSelector" :selected="form.targetIds" @selectionChanged="itemSelectionChanged" />
      <div slot="footer">
        <Button @click="showItemSelector=false" size="large" type="primary">确定</Button>
      </div>
    </Modal>

  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
//import PlanEditor from "@/components/operation/PlanEditor.vue"
import CashBackerEditor from "@/components/promocode/CashBackerEditor"
import ItemSelector from "@/components/promocode/ItemSelector"
import SellerSelector from "../../components/promocode/SellerSelector"
import { queryPromo, updatePromo, createPromo, deletePromo, genPromoCode, queryItemIds, queryItemByIds } from "@/api"
import { apiUtil, watchReload, editAndRemove } from "@/util"
import { PROMO_TYPES, HUMAN_PLAN_TYPE } from "@/constants"
import "@/components/tableUtil"

const initData = () => {
  return {
    name: "",
    desc: "",
    cashBackers: [],
    targetIds: [],
    isCashBack: false,
    code: "",
    price: 0,
    quantityNumber: 1,
    quantity: 0,
    quantityPerBuyer: 0,
    quantityPerBuyerNumber: 0,
    expired: 0,
    expiredDate: null,
    type: 1,
    promotionType: "discount",
    deductCond: 0,
    deductValue: 0,
    discountValue: 0,
    location: {
      country: "NewZealand",
      city: "",
      streetNo: "",
      region: "",
      street: "",
      suburb: "",
      lat: 0,
      lnt: 0,
      postCode: "",
    },
  }
}

export default {
  name: "PromoCreatePage",
  components: { ContentWrapper, Pager, CashBackerEditor, ItemSelector, SellerSelector },
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      PROMO_TYPES,
      topLinks: [{ title: "优惠码", link: this.$route.path }],
      isLoading: false,
      scheduleDateRange: ["", ""],
      createDateRange: ["", ""],
      page: 1,
      position: PROMO_TYPES[0].key,
      queryForm: { name: "" },
      dialogDisplay: false,
      dialogTitle: "",
      cashBacker: {
        targetLocation: "",
        targetUserId: -1,
        sellerName: "",
        quantity: 1,
        expired: 1,
        expiredDate: null,
        cashBackAmount: 0,
        editMode: false,
      },
      form: initData(),
      cashBackTable: [
        { title: "团长名称", key: "sellerName" },
        { title: "返现金额$", key: "cashBackAmount" },
        { title: "自提地址", key: "promoAddress" },
        {
          title: "操作",
          render: ((h, params) => {
            return h("ButtonGroup", [
              h("Button", {
                props: { type: "success" }, on: {
                  click: () => {
                    this.showCashBackDialog = true
                    this.cashBacker.editMode = true
                  },
                },
              }, "编辑"),
              h(
                "Button",
                {
                  on: {
                    click: () => {
                      this.form.cashBackers = this.form.cashBackers.filter(
                        item => item.targetUserId != params.row.targetUserId,
                      )
                    },
                  },
                },
                ["删除"],
              )])
          }).bind(this),
        },
      ],
      showCashBackDialog: false,
      showItemSelector: false,
      selectedItems: [],
      expiredDateOpts: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000
        },
      },
    }
  },
  computed: {
    positionIndex() {
      return PROMO_TYPES.reduce((acc, curr, idx) => {
        if (curr.name === this.position) {
          acc = idx
        }
        return acc
      }, 0)
    },
  },
  watch: {
    ...watchReload(["position", "queryForm", "scheduleDateRange", "createDateRange", "page"]),
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      apiUtil.call(this, async () => {

      })
    },
    async onNewSubmit() {
      apiUtil.call(
        this,
        async () => {
          const editor = this.$refs.editor
          const formData = Object.assign({}, editor.formData)
          const position = 0
          formData.scheduledAt = formData.scheduledAt ? formData.scheduledAt.getTime() : null
          formData.scheduledDownAt = formData.scheduledDownAt ? formData.scheduledDownAt.getTime() : null
          if (formData.planType < 0) {
            throw new Error("类型错误")
          }
          if (position.allowTypes.indexOf(formData.planType) == -1) {
            throw new Error("此位置不允许此类型的推广")
          }
          if (!formData.name) {
            throw new Error("没有有效的名称")
          }
          if (!formData.planValue) {
            formData.planValue = ""
          }
          if (!formData.position) {
            throw new Error("无效的位置")
          }
          if (!formData.priority || formData.priority < 0) {
            formData.priority = 0
          }
          if (formData.id) {
            await updatePromo(formData)
            this.$Message.success("编辑成功")
          } else {
            await createPromo(formData)
            this.$Message.success("创建成功")
          }
          await this.reload()
        },
        () => {
          this.dialogDisplay = true
        },
      )
    },
    async deletePromoCode(id) {
      apiUtil.call(this, async () => {
        await deletePromo(id)
        this.$Message.success("删除成功")
        await this.reload()
      })
    },
    async generatePormoCode() {
      var code = await genPromoCode()
      console.log(code)
      this.form.code = code

    },
    showAddCashBacker() {
      if (this.form.cashBackers && this.form.cashBackers.length >= 1) {
        this.$Message.error("返现团长最多一个")
        return
      }

      this.showCashBackDialog = true

    },
    addCashBacker() {
      if (this.cashBacker.editMode) { //删除旧的记录
        this.form.cashBackers = []
        this.cashBacker.editMode = false
      }
      if (this.form.cashBackers && this.form.cashBackers.length >= 1) {
        this.$Message.error("返现团长最多一个")
        return
      }
      if (this.form.cashBackers.filter(item => item.targetUserId == this.cashBacker.targetUserId).length > 0) {
        this.$Message.error("已经存在相同的商家")
        return
      }
      if (this.cashBacker.targetUserId === null || this.cashBacker.targetUserId <= 0) {
        this.$Message.error("请选择商家")
        return false
      }
      if (this.cashBacker.cashBackAmount <= 0) {
        this.$Message.error("请设置返现金额")
        return false
      }
      if (this.cashBacker.expired === 1) {
        console.log(this.cashBacker.expiredDate)
        if (!(this.cashBacker.expiredDate instanceof Date)) {
          this.$Message.error("请设置有效期")
          return
        }
      } else if (this.cashBacker.expired !== 1) {
        this.cashBacker.expiredDate = "无限"
      }
      var locs = [this.cashBacker.location.fullAddress,
        this.cashBacker.location.contact,
        this.cashBacker.location.phone,
      ]
      this.cashBacker.promoAddress = locs.join(" ")
      this.form.cashBackers.push(Object.assign({}, this.cashBacker))
      this.showCashBackDialog = false
    },
    async itemSelectionChanged(selection, selectItems) {
      this.form.targetIds = selection
      console.log(selectItems)
      this.selectedItems = await queryItemByIds(selection)
      const idMap = await queryItemIds(this.selectedItems.map(i => i.itemAlias))
      this.selectedItems.forEach(item => {
        item.id = idMap[item.itemAlias]
      })
    },
    removeItem(id) {
      console.log(this.selectedItems)
      this.form.targetIds = this.form.targetIds.filter(i => i != id)
      this.selectedItems = this.selectedItems.filter(u => u.id != id)
    },
    selectItems() {
      this.showItemSelector = false
    },
    async publish() {
      try {
        var promo = {}
        if (!this.form.name || this.form.name.length <= 0) {
          throw new Error("没有码名")
        }
        if (!this.form.code || this.form.code.length <= 0) {
          throw new Error("没有优惠码")
        }
        if (!this.form.targetIds || this.form.targetIds.length <= 0) {
          throw new Error("优惠对象未选择")
        }
        if (this.form.quantity === null) {
          throw new Error("码量未选择")
        }
        if (this.form.expired === null) {
          throw new Error("有效期限信息未选择")
        }

        if (!this.form.promotionType) {
          throw new Error("优惠码类型未选择")
        }
        if (this.form.promotionType !== "discount" && this.form.promotionType !== "deduct") {
          throw new Error("优惠类型不合法")
        }
        if (this.form.isCashBack === null) {
          throw new Error("是否返现未选择")
        }
        if (this.form.isCashBack && this.form.isCashBack === true && this.form.cashBackers.length <= 0) {
          throw new Error("返现商户未选择")
        }

        if (this.form.quantity !== 0) {
          if (!this.form.quantityNumber || this.form.quantityNumber <= 0) {
            throw new Error("优惠码数量不合法")
          }
          if (this.form.quantityPerBuyer !== 0) {
            if (this.form.quantityPerBuyerNumber > this.form.quantityNumber) {
              throw new Error("单个用户使用次数不能超过优惠码总量")
            }
          }
          promo.quantity = this.form.quantityNumber
        } else {
          promo.quantity = 0
        }

        if (this.form.quantityPerBuyer != 0) {
          promo.quantityPerBuyer = this.form.quantityPerBuyerNumber
        } else {
          promo.quantityPerBuyer = 0
        }

        if (this.form.promotionType === "deduct") {
          if (!this.form.deductValue) {
            throw new Error("直减没有金额信息")
          }
          if (this.form.deductCond < 0) {
            throw new Error("满足金额不能小于0")
          }
          if (this.form.deductValue === 0) {
            throw new Error("直减金额不能为 0 ")
          }

          promo.promotionType = this.form.promotionType
          promo.condition = Math.abs(parseFloat(this.form.deductCond / 0.01).toPrecision(7))
          promo.value = Math.abs(parseFloat(this.form.deductValue / 0.01).toPrecision(7))
        }

        if (this.form.promotionType === "discount") {
          if (!this.form.discountValue) {
            throw new Error("没有折扣比例")
          }

          if (this.form.discountValue <= 0) {
            throw new Error("折扣比例不合法，必须大于0")
          }
          promo.promotionType = this.form.promotionType
          promo.value = Math.abs(parseFloat(this.form.discountValue / 0.01).toPrecision(7))
          promo.condition = null
        }


        if (this.form.expired !== 0) {
          if (!this.form.expiredDate || !(this.form.expiredDate instanceof Date)) {
            throw new Error("优惠码过期时间不合法")
          }
          promo.expiredDate = this.form.expiredDate
        } else {
          promo.expiredDate = null // 没有过期日期
        }

        promo.expired = this.form.expired

        promo.isCashBack = this.form.isCashBack
        promo.cashBackGroup = []
        if (this.form.isCashBack === true) {
          const _cashBackGroup = this.form.cashBackers.map(a => Object.assign({}, a))//数组copy 需要map
          _cashBackGroup.forEach(
            item => {
              if (item.expired === 0) {
                item.expiredDate = null
              }
              if (item.cashBackAmount > 0) {
                item.cashBackAmount = Math.abs(parseFloat(item.cashBackAmount / 0.01).toPrecision(7))
              }
            },
          )
          promo.cashBackGroup = _cashBackGroup

        } else {
          promo.cashBackGroup = null
        }


        promo.targetIds = this.form.targetIds
        promo.name = this.form.name
        promo.desc = this.form.desc
        promo.code = this.form.code
        promo.type = this.form.type

        console.log(promo)
        const r = await createPromo(promo)
        this.$Message.success(`优惠码发布成功, ID: ${r}`)
        this.form = initData()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
  },
}
</script>
